<template>
  <div>
    <Spinner v-if="!cmsContentPending" class="mt-5 mb-5" size="medium" line-fg-color="#1f9979" />
    <footer v-else class="footer">
      <b-container>
        <b-row>
          <b-col cols="12" md="5">
            <router-link to="/" exact><img class="footer__logo img-fluid" v-lazy="this.footer_logo" alt="logo"></router-link>
            <p class="footer__text" v-html="$prismic.asHtml(this.footer_text)"></p>
            <div v-if="this.footer_list_1.length > 0" class="footer__list footer__list--bold margin-mobile">
              <p v-for="(item, index) in this.footer_list_1" :key="`${index}`">
                <a v-if="item.bullet_point == false" :href="item.footer_link.url" class="footer__link">{{ $prismic.richTextAsPlain(item.footer_label) }}</a>
                <ul v-else>
                  <li><a :href="item.footer_link.url">{{ $prismic.richTextAsPlain(item.footer_label) }}</a></li>
                </ul>
              </p>
            </div>
          </b-col>
          <b-col v-if="this.footer_list_2.length > 0" class="margin-mobile" cols="12" md="6" lg="3" offset-md="1">
            <p v-for="(item, index) in this.footer_list_2" :key="`${index}`">
              <a v-if="item.bullet_point == false" :href="item.footer_link.url" class="footer__link">{{ $prismic.richTextAsPlain(item.footer_label) }}</a>
              <ul v-else class="footer__list">
                <li><a :href="item.footer_link.url" class="footer__link">{{ $prismic.richTextAsPlain(item.footer_label) }}</a></li>
              </ul>
            </p>
          </b-col>
          <b-col v-if="this.footer_list_3.length > 0" class="margin-mobile" cols="12" md="12" lg="3">
            <p v-for="(item, index) in this.footer_list_3" :key="`${index}`">
              <a v-if="item.bullet_point == false" :href="item.footer_link.url" class="footer__link">{{ $prismic.richTextAsPlain(item.footer_label) }}</a>
              <ul v-else class="footer__list">
                <li><a :href="item.footer_link.url" class="footer__link">{{ $prismic.richTextAsPlain(item.footer_label) }}</a></li>
              </ul>
            </p>
          </b-col>
        </b-row>
      </b-container>
    </footer>
  </div>
</template>

<script>
import Spinner from 'vue-simple-spinner'
export default {
  name: 'FooterComponent',
  components: {
    Spinner
  },
  data: () => {
    return {
      cmsContentPending: false,
      footer_logo: null,
      footer_text: null,
      footer_features: null,
      footer_list_1: [],
      footer_list_2: [],
      footer_list_3: []
    }
  },
  methods: {
    async getFooterContent () {
      await this.$prismic.client.getSingle('footer')
        .then((document) => {
          // footer section
          this.footer_logo = document.data.footer_logo.url
          this.footer_text = document.data.footer_text
          this.footer_features = document.data.footer_features
          this.footer_list_1 = document.data.footer_list_1
          this.footer_list_2 = document.data.footer_list_2
          this.footer_list_3 = document.data.footer_list_3
          this.cmsContentPending = true
        })
    }
  },
  created () {
    this.getFooterContent()
  }
}
</script>

<style lang="scss">
  @import "./FooterComponent.scss";
</style>
